import React, {useState, useEffect, useContext} from "react";
import { CheckIcon, PlusCircledIcon } from "@radix-ui/react-icons";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/ui/popover";
import { Separator } from "components/ui/separator";
import { AppContext } from 'contexts/AppContext'

function DataTableFacetedFilter({ column, title, options, mutuallyExclusive = false }) {
  const appContext = useContext(AppContext);
  const facets = column?.getFacetedUniqueValues();
  const selectedValues = new Set(column?.getFilterValue() || []);

  const handleSelect = (optionValue) => {
    // Safely check if logEvent exists before calling it
    if (appContext && typeof appContext.logEvent === 'function') {
      appContext.logEvent('Customer Table', 'Filter', {"filter": optionValue});
    }
    
    // Handle mutually exclusive selection (radio button behavior)
    if (mutuallyExclusive) {
      // If the item is already selected, deselect it (clear filter)
      if (selectedValues.has(optionValue)) {
        column.setFilterValue(undefined);
      } else {
        // Otherwise select only this item
        column.setFilterValue([optionValue]);
      }
      return;
    }
    
    // Original multi-select behavior (checkbox)
    const newSelectedValues = new Set(selectedValues);
    
    if (newSelectedValues.has(optionValue)) {
      newSelectedValues.delete(optionValue);
    } else {
      newSelectedValues.add(optionValue);
    }
    
    // Convert to array for the filter
    const arrayValues = Array.from(newSelectedValues);
    
    // If no values selected, set to undefined to clear the filter
    if (arrayValues.length === 0) {
      column.setFilterValue(undefined);
    } else {
      column.setFilterValue(arrayValues);
    }
  };

  // Check if column exists
  if (!column) {
    return null;
  }

  return (
    <Popover>
      <PopoverTrigger asChild>             
        <Button variant="outline" size="sm" className="h-8 border-dashed w-full lg:w-auto">
          <PlusCircledIcon className="mr-2 h-4 w-4" />
          {title}
          {selectedValues.size > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                {selectedValues.size}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {mutuallyExclusive || selectedValues.size <= 2 ? (
                  // For mutually exclusive filters or small selections, show each value
                  Array.from(selectedValues).map((value) => (
                    <Badge key={value} variant="secondary" className="rounded-sm px-1 font-normal">
                      {options.find((option) => option.value === value)?.label || value}
                    </Badge>
                  ))
                ) : (
                  // For larger non-exclusive selections, show count
                  <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                    {selectedValues.size} selected
                  </Badge>
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className={`${mutuallyExclusive ? 'w-[200px]' : 'w-[360px]'} p-0`} align="start">
        <Command>
          <CommandInput placeholder={`Search ${title}`} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isSelected = selectedValues.has(option.value);
                return (
                  <CommandItem
                    key={option.value}
                    onSelect={() => handleSelect(option.value)}
                  >
                    <div className={`mr-2 flex h-4 w-4 items-center justify-center rounded-sm border ${isSelected ? "bg-primary text-primary-foreground" : "opacity-50"}`}>
                      {isSelected && <CheckIcon className="h-4 w-4" />}
                    </div>
                    <span>{option.label}</span>
                    {facets?.get(option.value) && (
                      <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs">
                        {facets.get(option.value)}
                      </span>
                    )}
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedValues.size > 0 && (
              <>
                <CommandSeparator />
                <CommandItem
                  onSelect={() => {
                    column.setFilterValue(undefined);
                    // Safely check if logEvent exists before calling it
                    if (appContext && typeof appContext.logEvent === 'function') {
                      appContext.logEvent('Customer Table', 'Clear Filters', '');
                    }
                  }}
                  className="justify-center text-center"
                >
                  Clear filters
                </CommandItem>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export default DataTableFacetedFilter;
