import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';

const ALLOWED_DOMAINS = ['srsdistribution.com', 'ur.com', 'instalily.ai','mmhfgb.com'];
const ALLOWED_EMAILS = ['cristinconnerney@gmail.com',"aaron.spitz@heritagepsg.com","anurag.jalan@srsdistribution.com","mark.warshaw@heritagepsg.com","mary.pottkotter@heritagepsg.com",
  "rin.barry@heritagepsg.com","chris.cataldo@heritagelsg.com","steve.okelberry@heritagelsg.com","kjackson6@ur.com","efuller1@ur.com","pmaddison@ur.com","hello@herainetwork.com", "mp203598@mmhfgb.com",
  "aj097979@mmhfgb.com", "kent.jordan@heritagelsg.com"
]

const AuthGuard = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect, user, logout } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  if (isLoading) {
    // Show a loading indicator while checking authentication
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    // Return null to prevent rendering unauthorized content
    return null;
  }

  const emailDomain = user.email.split('@')[1];

  const isAllowedUser = ALLOWED_EMAILS.includes(user.email) || ALLOWED_DOMAINS.includes(emailDomain);

  if (!isAllowedUser) {
    // Redirect unauthorized users or log them out
    logout({ logoutParams: { returnTo: window.location.origin } })
    return <div>Access denied. Please use an approved email to log in.</div>;
  }

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
