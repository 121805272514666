import React, { useContext } from "react";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import DataTableViewOptions from "./data-table-view-options";
import { ListRestart, DownloadIcon, XCircle } from "lucide-react";
import { AppContext } from "contexts/AppContext";
import DataTableFacetedFilter from "./data-table-faceted-filter";

function formatDate(date) {
  return date instanceof Date && !isNaN(date.getTime())
    ? date.toISOString().split("T")[0]
    : ""; // Return an empty string for invalid dates
}

function DataTableToolbar({ table }) {
  const { dateRange, setDateRange, selectedUser, setSelectedUser, selectedClient } = useContext(AppContext);

  const isFiltered = table.getState().columnFilters.length > 0;

  const handleSearchChange = (e) => {
    const newSearch = e.target.value;
    table.setGlobalFilter(newSearch);
  };

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    
    // Check if the value is a valid date
    const parsedDate = new Date(newStartDate);
    if (isNaN(parsedDate.getTime())) {
      console.error("Invalid start date:", newStartDate);
      setDateRange([null,dateRange[1]]);
      return; // Exit the function if the date is invalid
    }
  
    setDateRange([parsedDate, dateRange[1]]);
    table.setColumnFilters([
      {
        id: "date",
        value: { startDate: newStartDate, endDate: dateRange[1] ? formatDate(dateRange[1]) : null },
      },
    ]);
  };
  
  const handleEndDateChange = (e) => {
    if (!e) return
    const newEndDate = e.target.value;

    const parsedDate = new Date(newEndDate);
    if (isNaN(parsedDate.getTime())) {
      console.error("Invalid end date:", newEndDate);
      etDateRange([dateRange[0], null]);
      return; // Exit the function if the date is invalid
    }

    setDateRange([dateRange[0], new Date(newEndDate)]);
    table.setColumnFilters([
      { id: "date", value: { startDate: dateRange[0] ? formatDate(dateRange[0]) : null, endDate: newEndDate } }, // Replace "date" with your column ID
    ]);
  };

  const resetTable = () => {
    table.resetSorting();
    table.resetColumnFilters();
    table.setGlobalFilter("");
    table.resetRowSelection();
    table.resetColumnVisibility();
    setSelectedUser(null)
    setDateRange([
      new Date(new Date().setDate(new Date().getDate() - 29)), // 30 days ago
      new Date() // Today
    ]);
  };  

  const handleClearUserFilter = () => {
    setSelectedUser(null); // Clear the selected user when clicking the "X"
  };

  const handleDownload = () => {
    // 1) Get the "real" columns you want to export (only visible, etc.)
    const visibleColumns = table
      .getAllColumns()
      .filter((col) => col.getIsVisible());
  
    // 2) Build an array of header names
    // (These come from columnDef.header or from col.id.)
    const headers = visibleColumns.map((col) => {
      return typeof col.columnDef.header === "string"
        ? col.columnDef.header
        : col.id;
    });
  
    // 3) Get the rows you want to export (filtered or all)
    const rows = table.getPrePaginationRowModel().rows;

    // 4) Convert each row to raw data, column by column
    const csvRows = rows.map((row) => {
      // row.original is typically the raw data object for that row
      // You can also do row.getValue(col.id) if col.accessorKey is set up
      return visibleColumns.map((col) => {
        const accessorKey = col.columnDef.accessorKey;
  
        // If we have an accessorKey, grab from row.original
        if (accessorKey) {
          return wrapAndEscapeCsvValue(row.original[accessorKey]);
        }
        // Otherwise we might try row.getValue(col.id) or just blank
        const cellValue = row.getValue(col.id);
        return wrapAndEscapeCsvValue(cellValue);
      }).join(",");
    });
  
    // 5) Combine headers + rows
    const csvString = [
      headers.join(","), // a header row
      ...csvRows
    ].join("\n");
  
    // 6) Trigger the CSV file download
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "table_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  // Utility to wrap values in quotes and escape existing quotes
  function wrapAndEscapeCsvValue(value) {
    if (value == null) return "";  // handle null/undefined
    const strValue = String(value);
    // Escape existing quotes by doubling them, then wrap in quotes
    return `"${strValue.replace(/"/g, '""')}"`;
  }  

  return (
    <div className="flex flex-col lg:flex-row items-center justify-between space-y-2 lg:space-y-0 lg:space-x-2">
      <div className="flex flex-col lg:flex-row flex-1 items-center space-y-2 lg:space-y-0 lg:space-x-2 w-full">
        <Input
          placeholder={`Search ${table.getFilteredRowModel().rows.length} Rows...`}
          value={table.getState().globalFilter || ""}
          onChange={handleSearchChange}
          className="h-8 w-full lg:w-[200px]"
        />
        <Input
          type="date"
          value={dateRange[0] ? formatDate(dateRange[0]) : ""}
          onChange={handleStartDateChange}
          placeholder="Start Date"
          className="h-8 w-full lg:w-[150px]"
        />
        <Input
          type="date"
          value={dateRange[1] ? formatDate(dateRange[1]) : ""}
          onChange={handleEndDateChange}
          placeholder="End Date"
          className="h-8 w-full lg:w-[150px]"
        />
        
        {/* Account Type Filter - only show for heritage and heritagepool clients */}
        {selectedClient && 
          (selectedClient.key === "heritage" || selectedClient.key === "heritagepool") && 
          (() => {
            // Check if the column exists by looking at all available columns
            const hasAccountTypeColumn = table.getAllColumns().some(
              column => column.id === "account_type"
            );
            
            if (hasAccountTypeColumn) {
              return (
                <DataTableFacetedFilter
                  column={table.getColumn("account_type")}
                  title="Account Type"
                  options={[
                    { value: "Internal", label: "Internal" },
                    { value: "External", label: "External" },
                  ]}
                  mutuallyExclusive={true}
                />
              );
            }
            return null;
          })()
        }
      </div>
      {/* <DataTableViewOptions table={table} /> */}

      {selectedUser && (
        <div className="flex items-center space-x-2 ml-auto">
          <span className="bg-blue-700 text-white h-8 text-xs px-2 py-1 rounded-full flex items-center">
            {selectedUser || "User Selected"}
            <button onClick={handleClearUserFilter} className="ml-2 text-white">
              <XCircle className="h-4 w-4" />
            </button>
          </span>
        </div>
      )}

      <Button
        variant="outline"
        onClick={() => resetTable()}
        className="h-8 px-2 lg:px-3 w-full lg:w-auto ml-auto"
      >
        <ListRestart className="mr-2 h-4 w-4" />
        Reset Filters
      </Button>
      <Button
        variant="outline"
        onClick={handleDownload}
        className="h-8 px-2 lg:px-3 w-full lg:w-auto ml-auto"
      >
        <DownloadIcon className="h-4 w-4" />
      </Button>
    </div>
  );
}

export default DataTableToolbar;
