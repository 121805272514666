import React, { createContext, useContext, useState, useEffect, useRef } from "react";
import useLocalStorage from 'utils/useLocalStorage';
import subDays from 'date-fns/subDays';
import { format } from 'date-fns';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment'; 
import axios from 'axios';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {

    // BY DOMAIN: Gives clients access to specific databases, also includes schemas for that database needed throughout the application 
    const clientMapping = {
        "instalily.ai": [
          { key: "heritagepool", label: "HeritagePool+", imageUrl: "/logos/heritagepool+-logo.png", schema: "heritagepool_schema" },
          { key: "heritage", label: "Heritage+", imageUrl: "/logos/heritage+-logo.png", schema: "heritage_schema" },
          { key: "unitedrentals", label: "United Rentals", imageUrl: "/logos/united-rentals-logo.png", schema: "unitedrentals_schema" }
        ],
        "ur.com": [
          { key: "unitedrentals", label: "United Rentals", imageUrl: "/logos/united-rentals-logo.png", schema: "unitedrentals_schema" }
        ],
        "srsdistribution.com": [
          { key: "heritagepool", label: "HeritagePool+", imageUrl: "/logos/heritagepool+-logo.png", schema: "heritagepool_schema" },
          { key: "heritage", label: "Heritage+", imageUrl: "/logos/heritage+-logo.png", schema: "heritage_schema" }
        ],
        'mmhfgb.com': [
          { key: "heritagepool", label: "HeritagePool+", imageUrl: "/logos/heritagepool+-logo.png", schema: "heritagepool_schema" },
          { key: "heritage", label: "Heritage+", imageUrl: "/logos/heritage+-logo.png", schema: "heritage_schema" }
        ],
    };      

    // BY EMAIL: Gives access to a databases by assigning additional user emails to the domain
    const additionalEmailAccess = {
        "srsdistribution.com": ["aaron.spitz@heritagepsg.com","anurag.jalan@srsdistribution.com","mark.warshaw@heritagepsg.com","mary.pottkotter@heritagepsg.com",
            "rin.barry@heritagepsg.com","chris.cataldo@heritagelsg.com","steve.okelberry@heritagelsg.com","mp203598@mmhfgb.com","aj097979@mmhfgb.com", "kent.jordan@heritagelsg.com"
        ],
        "instalily.ai": ["cristinconnerney@gmail.com"],
        "ur.com": ["kjackson6@ur.com","efuller1@ur.com","pmaddison@ur.com","hello@herainetwork.com"]
    };

    const { user } = useAuth0();
    const [emailDomain, setEmailDomain] = useState(null);
    const [clientOptions, setClientOptions] = useState([]);
    const [selectedClient, setSelectedClient] = useLocalStorage('selectedClient',null);
  
    useEffect(() => {
        if (user) {
          const domain = user.email.split("@")[1];
      
          if (clientMapping[domain]) {
            // Domain-based access
            setEmailDomain(domain);
            setClientOptions(clientMapping[domain]);
          } else {
            // Email-specific access
            let assignedDomain = null;
      
            for (const [allowedDomain, emails] of Object.entries(additionalEmailAccess)) {
              if (emails.includes(user.email)) {
                assignedDomain = allowedDomain;
                break;
              }
            }
      
            if (assignedDomain) {
              setEmailDomain(assignedDomain);
              setClientOptions(clientMapping[assignedDomain]);
            } else {
              // No access
              setEmailDomain(null);
              setClientOptions([]);
              setSelectedClient(null);
            }
          }
        }
    }, [user]);
    
    useEffect(() => {
        if (!selectedClient && clientOptions.length > 0) {
            setSelectedClient(clientOptions[0]);
        }
    }, [clientOptions, selectedClient]);      
 
    const base_url = "https://monitoring-backend-p6j3rbf6oa-uc.a.run.app" // 'http://127.0.0.1:8000' // 
    
    const { getAccessTokenSilently } = useAuth0();

    const [dateRange, setDateRange] = useState([
        new Date(new Date().setDate(new Date().getDate() - 29)), // 30 days ago
        new Date() // Today
    ]);

    const [selectedUser, setSelectedUser] = useLocalStorage("selectedUser",null)
    const [selectedSession, setSelectedSession] = useLocalStorage("selectedSession",null)

    const [loading, setLoading] = useState(false)

    const [messagesData, setMessagesData] = useState([])
    const [userData, setUserData] = useState([])
    const [sessionData, setSessionData] = useState([])

    const [activeTab, setActiveTab] = useLocalStorage("activeTab","Users");

    const getMessages = async () => {
        const token = await getAccessTokenSilently();
        const adjustedEndDate = new Date(dateRange[1]);
        adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);
        const content = {
            'user_id': selectedUser,
            'session_id': selectedSession,
            'start_date': dateRange[0],
            'end_date': adjustedEndDate,
            'client': selectedClient.key
        }
        try {
            const response = await axios.post(`${base_url}/send/get-messages`, content, 
                { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`, } });            
            setMessagesData(response.data.data);
        } catch (error) {
            console.error('Error getting message data:', error);
        }
    };

    const getUsers = async () => {
        const token = await getAccessTokenSilently();
        const adjustedEndDate = new Date(dateRange[1]);
        adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);
        const content = {
            'user_id': selectedUser,
            'session_id': selectedSession,
            'start_date': dateRange[0],
            'end_date': adjustedEndDate,
            'client': selectedClient.key
        }
        try {
            const response = await axios.post(`${base_url}/send/get-users`, content, 
                { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`, } });            
                setUserData(response.data.data);
        } catch (error) {
            console.error('Error getting message data:', error);
        }
    };

    useEffect(() => {
        console.log("client",selectedClient, clientOptions)
        if (selectedClient && clientOptions.length > 0) {
            setLoading(true);
            Promise.all([getMessages(), getUsers()]).then(() => {
                setLoading(false);
            });
        }
    }, [selectedClient, dateRange, selectedUser, clientOptions]);

    useEffect(() => {
        console.log(messagesData)
        console.log(userData)
    }, [messagesData,userData]);

    const onRowClick = async (id) => {
        console.log(id)
        setSelectedUser(id)
        setActiveTab("Messages")
    };

    return (
        <AppContext.Provider
        value={{ clientOptions, selectedClient, setSelectedClient, dateRange, setDateRange, messagesData, loading, userData,
            activeTab, setActiveTab, selectedUser, setSelectedUser, onRowClick
        }}>
        {children}
        </AppContext.Provider>
    );
};
